import "./App.css";
import MainLayout from "./components/MainLayout";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
// import Home from "./pages/Home.jsx";
import { useEffect } from "react";
import LogIn from "./pages/login/LogIn.jsx";
import Dashboard from "./pages/Dashboard.jsx";
import AddCategory from "./pages/addcategory/AddCategory.jsx";
import ListCategory from "./pages/listcategory/ListCategory.jsx";
import AddProjects from "./pages/projects/AddProjects.jsx";
import ListProject from "./pages/projects/ListProject.jsx";
import Edit from "./pages/edit/Edit.jsx";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/LogIn" element={<LogIn />} />
          <Route path="/" exact element={<MainLayout />}>
          <Route path="/Dashboard" element={<Dashboard />}/>
          <Route path="/AddCategory" element={<AddCategory />}/>
          <Route path="/ListCategory" element={<ListCategory />}/>
          <Route path="/AddProjects" element={<AddProjects />}/>
          <Route path="/ListProject" element={<ListProject />}/>
          <Route path="/Edit" element={<Edit />}/>
            {/* <Route index element={<Home />} /> */}

          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
