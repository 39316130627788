import React from 'react'
import { Link } from "react-router-dom";


const LogIn = () => {
    return (
        <>
            <div className="hold-transition register-page">
                <div className="register-box col-sm-5">
                    <div className="card card-outline card-primary">
                        <div className="card-header text-center">
                            <Link to="/" className="h1">
                            Green Ville Projects
                            </Link>
                        </div>
                        <div className="card-body">
                            <p className="login-box-msg d-flex justify-content-center">
                                Sign in to start your session
                            </p>
                            <form>
                                <div className="input-group mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">

                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"

                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-2">
                                    <Link to="/Dashboard">
                                        <button type="submit" className="btn btn-success ">
                                            Login
                                        </button></Link>
                                    </div>
                                    <div className="">
                                        <Link to="../login">I forgot my password</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LogIn