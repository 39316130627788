import React from 'react'
import { Link } from "react-router-dom";


const ListProject = () => {
    return (
        <>

            <div className="content-wrapper ">
                <div className="card">
                    <div className="card-footer">
                        <h5>List Project</h5>
                    </div>
                    <div
                        style={{ height: 455, width: "100%" }}
                        className="d-flex m-auto text-align-center"
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className>
                                        <div className="table-responsive">
                                            <table className="table project-list-table table-nowrap align-middle table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="ps-4" style={{ width: 50 }}>
                                                            <div className="form-check font-size-16">
                                                                <label className="form-check-label" htmlFor="contacusercheck" /></div>
                                                        </th>
                                                        <th scope="col">Category Name</th>
                                                        <th scope="col">Category Title</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Action</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" className="ps-4">
                                                            <div className="form-check font-size-16"><input type="checkbox" className="form-check-input" id="contacusercheck1" /><label className="form-check-label" htmlFor="contacusercheck1" /></div>
                                                        </th>
                                                        <td>Simon Ryles</td>
                                                        <td>SimonRyles@minible.com</td>
                                                        <td><img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt className="avatar-sm rounded-circle me-2" /></td>
                                                        <td> <Link to="/Edit"><span className="badge badge-soft-success mb-0" >Edit</span></Link></td>
                                                        <td><span class="badge badge-soft-danger mb-0">Delete</span></td>

                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" className="ps-4">
                                                            <div className="form-check font-size-16"><input type="checkbox" className="form-check-input" id="contacusercheck1" /><label className="form-check-label" htmlFor="contacusercheck1" /></div>
                                                        </th>
                                                        <td>Simon Ryles</td>
                                                        <td>SimonRyles@minible.com</td>
                                                        <td><img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt className="avatar-sm rounded-circle me-2" /></td>
                                                        <td> <Link to="/Edit"><span className="badge badge-soft-success mb-0" >Edit</span></Link></td>
                                                        <td><span class="badge badge-soft-danger mb-0">Delete</span></td>

                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" className="ps-4">
                                                            <div className="form-check font-size-16"><input type="checkbox" className="form-check-input" id="contacusercheck1" /><label className="form-check-label" htmlFor="contacusercheck1" /></div>
                                                        </th>
                                                        <td>Simon Ryles</td>
                                                        <td>SimonRyles@minible.com</td>
                                                        <td><img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt className="avatar-sm rounded-circle me-2" /></td>
                                                        <td> <Link to="/Edit"><span className="badge badge-soft-success mb-0" >Edit</span></Link></td>
                                                        <td><span class="badge badge-soft-danger mb-0">Delete</span></td>

                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" className="ps-4">
                                                            <div className="form-check font-size-16"><input type="checkbox" className="form-check-input" id="contacusercheck1" /><label className="form-check-label" htmlFor="contacusercheck1" /></div>
                                                        </th>
                                                        <td>Simon Ryles</td>
                                                        <td>SimonRyles@minible.com</td>
                                                        <td><img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt className="avatar-sm rounded-circle me-2" /></td>
                                                        <td> <Link to="/Edit"><span className="badge badge-soft-success mb-0" >Edit</span></Link></td>
                                                        <td><span class="badge badge-soft-danger mb-0">Delete</span></td>

                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" className="ps-4">
                                                            <div className="form-check font-size-16"><input type="checkbox" className="form-check-input" id="contacusercheck1" /><label className="form-check-label" htmlFor="contacusercheck1" /></div>
                                                        </th>
                                                        <td>Simon Ryles</td>
                                                        <td>SimonRyles@minible.com</td>
                                                        <td><img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt className="avatar-sm rounded-circle me-2" /></td>
                                                        <td> <Link to="/Edit"><span className="badge badge-soft-success mb-0" >Edit</span></Link></td>
                                                        <td><span class="badge badge-soft-danger mb-0">Delete</span></td>

                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




            </div>
        </>
    )
}

export default ListProject