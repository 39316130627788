import React from 'react'
import { Link } from "react-router-dom";
import { RiUserSettingsFill, RiHandHeartFill } from "react-icons/ri";
import { MdOutlineLibraryAdd, MdOutlineDevicesOther } from "react-icons/md";
import {
    FaBuffer,
    FaHandHoldingMedical,
    FaHome,
    FaDisease,
  } from "react-icons/fa";






const Menu = () => {
  return (
    <>
         <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/Dashboard" className="brand-link">
          <img
            // src={logo}
            src='./dist/img/green/finallogo3 (1).jpg'
            alt="Admin"
            className="brand-image img-circle elevation-3 "
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light"> Super Admin</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          <nav className="mt-2">
            {/*================================ Dashboard ==========================*/}
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item has-treeview">
                <Link to="/Dashboard" className="nav-link">
                  <span>
                    <FaHome />
                  </span>
                  <p>Dashboard</p>
                </Link>
              </li>
 

               {/*============================ category Start ================================*/}

              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Category
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/AddCategory" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Add Category</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/ListCategory" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>List Category</p>
                    </Link>
                  </li>
                </ul>
              </li>

               {/*============================ category End ================================*/}


                        {/*============================ project Start ================================*/}

              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Project
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/AddProjects" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Add Project</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/ListProject" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>List Project</p>
                    </Link>
                  </li>
                </ul>
              </li>

               {/*============================ project End ================================*/}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  )
}

export default Menu