import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { CgMaximizeAlt } from "react-icons/cg";
import { BsPersonCircle } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";



const Hadear = () => {
    const [toggle, setToggle] = useState(false);


    return (

        <>
            <div>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#">
                                <i className="fas fa-bars" />
                            </a>
                        </li>
                    </ul>
                    <Link to="/LogIn" className="headerDesign">
                    <ul className="headerDesign">
                        <li>
                            <span>
                                <CgMaximizeAlt />
                            </span>
                        </li>

                        <li>
                        <Link to="/LogIn">
                            <span>
                                <BiLogOut />
                            </span>
                            <span className="m-2 mt-2">Green Ville</span></Link>
                        </li>
                            <li>
                                <span>
                                    <BsPersonCircle />
                                </span>
                            </li>
                        

                    </ul></Link>
                </nav>
            </div>


        </>
    )
}

export default Hadear