import React from 'react'
import { Link } from "react-router-dom";


const Footer = () => {
    return (
        <>

            <div>
                <footer className="main-footer">
                    <strong>
                        Copyright © <Link to="/admin" className="text-success px-2" >Green Ville Projects</Link>
                    </strong>
                    All rights reserved.
                </footer>
            </div>

        </>
    )
}

export default Footer