import React from 'react'

const AddProjects = () => {
    return (
        <>

            <div className="content-wrapper">
                <div className="employeesContainer">
                    <form enctype="multipart/form-data">
                        <div className="card m-4">
                            <div className="card-footer">
                                <h5>Add Projects</h5>
                            </div>

                            <form>
                                <div className="form-row container">
                                    <div className="form-group col-md-6 ">
                                        <label htmlFor="inputEmail4">Category *</label>
                                        <input type="text" className="form-control" placeholder="Enter Name" />
                                       


                                    </div>
                                    <div className="form-group col-md-6 ">
                                        <label htmlFor="inputEmail4">Name *</label>
                                        <input type="text" className="form-control" placeholder="Enter Name" />
                                    </div>
                                    <div className="form-group col-md-6 ">
                                        <label htmlFor="inputEmail4">Client Comments *</label>
                                        <input type="text" className="form-control" placeholder="Enter Client Comments" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputPassword4">Title*</label>
                                        <input type="text" className="form-control" placeholder=" Enter Title" />
                                    </div>
                                </div>
                                <div className="form-row container">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputEmail4">Short Description *</label>
                                        <input type="Text" className="form-control" placeholder="Enter Short Description " />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputPassword4">Long Description *</label>
                                        <input type="text" className="form-control" placeholder="Enter Long Description" />
                                    </div>
                                </div>
                            </form>

                            <div className='form-row container'>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="formFile" className="form-label">Home Image</label>
                                    <input className="form-control" type="file" id="formFile" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="formFileMultiple" className="form-label">Category Image</label>
                                    <input className="form-control" type="file" id="formFileMultiple" multiple />
                                </div>
                            </div>
                            <div className="card-footer ">
                                <button className="btn btn-success" type="submit">
                                    Add Category
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddProjects